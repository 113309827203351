<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <h2>{{ $route.meta.title[$i18n.locale] }}</h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'faq-create' })"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button>
    </a-row>
    <a-row type="flex" class="table__filtrs">
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        {{ $t("Delete") }}
      </a-button>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="$store.getters['faq/list']"
      :loading="loading"
      :pagination="{ defaultPageSize: 10 }"
      :row-key="() => Math.random().toString().slice(2)"
      class="faqs"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template>
      <template slot="position" slot-scope="item">
        <a-input-number
          v-model="item.position"
          style="width: 50px"
          @change="updatePosition(item, $event)"
        />
      </template>

      <template slot="theme" slot-scope="item">
        <span
          class="text-center td-post-item"
          style="cursor: pointer; display: block; width: 100%; text-align: left"
          @click="$router.push({ name: 'faq-detail', params: { id: item.id } })"
          >{{ item.question || "Не заполнено" }}</span
        >
      </template>
      <template slot="is_active" slot-scope="item">
        <a-switch
          v-model="item.is_active"
          style="margin-right: 15px"
          @change="checkActive(item, $event)"
        />
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? "Опубликован" : "Не опубликован" }}
        </a-tag>
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="$router.push({ name: 'faq-detail', params: { id: item.id } })"
        >
          {{ $t("Edit") }}
          <a-icon type="edit" class="action-btns" />
        </a-button>
      </template>
    </a-table>
  </a-row>
</template>

<script>
export default {
  data(){
    return {
    loading: false,
    columns: [
      {
        key: "id",
        scopedSlots: { customRender: "id" },
      },
      {
        title: "Поз.",
        key: "position",
        scopedSlots: { customRender: "position" },
        className: "text-center font-md",
      },
      {
        title: "Вопрос",
        key: "theme",
        scopedSlots: { customRender: "theme" },
        width: "60%",
      },
      {
        title: this.$t("Status"),
        key: "is_active",
        width: "20%",
        scopedSlots: { customRender: "is_active" },
      },
      {
        title: "Действие",
        key: "operation",
        scopedSlots: { customRender: "operation" },
        width: "10%",
      },
    ],
    selectedRowKeys: [],
    selectedPosts: [],
    cardsIsEmpty: true,
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
  },
  methods: {
    updatePosition(item, position) {
      this.loading = true;
      this.$api
        .put(`/admin/faq/${item.id}`, {
          position,
        })
        .then(() => {
          this.loading = false;
          this.fetchData();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item == +itemId);
      if (f != -1) {
        this.selectedPosts.splice(f, 1);
        this.cardsIsEmpty = false;
      } else {
        this.selectedPosts.push(itemId);
        this.cardsIsEmpty = false;
      }
      if (this.selectedPosts.length == 0) {
        this.cardsIsEmpty = true;
      }
    },
    async checkActive(item, event) {
      try {
        await this.$api.put(`/admin/faq/${item.id}`, {
          is_active: event,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        await this.$store.dispatch("faq/fetch");
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.loading = false;
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const { status } = await this.$store.dispatch("faq/remove", i);
            if (status) {
              this.$message.success("Успешно удаленно");
              this.fetchData();
            }
          } catch (e) {
            this.$sentry.captureMessage(e);
          }
        }
      } else {
        try {
          const res = await this.$store.dispatch("faq/remove", id);
          if (res) {
            this.$message.success("Успешно удаленно");
            this.fetchData();
          }
        } catch (e) {
          this.$sentry.captureMessage(e);
        }
      }
      this.selectedCards = [];
      this.cardsIsEmpty = true;
    },
  },
};
</script>

<style>
.faqs .ant-btn-primary {
  background-color: #26a6de;
}
</style>
